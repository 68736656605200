import * as React from 'react';

// markup
const IndexPage = () => {
    return (
        <main>
            <title>ArcEngine</title>
            <h1>ArcEngine</h1>
            <p>Visit the App <a href="https://app.arcengine.dev">https://app.arcengine.dev</a></p>
        </main>
    );
};

export default IndexPage;
